





import { Component, Vue, Prop } from "vue-property-decorator";
import Patient from "../../components/patient/patient.vue";
@Component({
  components: {
    Patient,
  },
})
export default class Name extends Vue {}
