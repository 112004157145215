














import { Component, Vue, Prop } from "vue-property-decorator";
import AgreeFqxk from "./agree-fqxk.vue";
import AgreeScmb from "./agree-scmb.vue";
@Component({
  components: {
    AgreeScmb,
    AgreeFqxk,
  },
})
export default class Name extends Vue {
  @Prop()
  private visible: any;
  private get ifShow() {
    return this.visible;
  }
  private set ifShow(val: any) {
    this.$emit("updateAgree", val);
  }
  private get configuration() {
    return this.$store.state.configuration;
  }
}
