





























































































import { Component, Vue, Prop } from "vue-property-decorator";

export default class Name extends Vue {
  private get configuration() {
    return this.$store.state.configuration;
  }
}
