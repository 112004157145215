


















































import { Component, Vue, Prop } from "vue-property-decorator";
import { UploadFile } from "./template-transfer-pc/template-transfer/request";
@Component({})
export default class Name extends Vue {
  @Prop()
  private content: any;
  private limitedNumber: any = 0;
  private acceptedType: any = "";
  private get c() {
    if (!this.content) {
      const d = { 病史资料: { value: [] } };
      return d;
    } else {
      const d = JSON.parse(JSON.stringify(this.content));
      if (!d["病史资料"] || !d["病史资料"].value) {
        d["病史资料"] = { value: [] };
      }
      return d;
    }
  }
  /**
   * @description 判断是否为图片需要展示
   */
  private ifPicture(file: any) {
    return true;
    const arr: any = ["png", "jpeg", "jpg"];
    let exsit = false;
    arr.map((type: any) => {
      if (file.url.indexOf(type) !== -1) {
        exsit = true;
      }
    });
    return exsit;
  }
  /**
   * @description 超出数量时提示
   */
  private exceedHelp() {
    this.$message.warning("该项最多只能上传" + this.limitedNumber + "个文件");
  }
  /**
   * @description 图片上传预处理
   */
  private beforeUpload(file: any) {
    return true;
    const arr = file.name.split(".");
    const fileType = arr[arr.length - 1];
    if (this.acceptedType.includes(fileType)) {
      return true;
    } else {
      this.$message.warning("只能上传特定类型的文件");
      return false;
    }
  }
  private deleteOne(i: any) {
    const d = JSON.parse(JSON.stringify(this.content));
    d.value.splice(i, 1);
    this.c.value.splice(i, 1);
    this.$emit("update", d);
  }
  /**
   * @description 删除文件
   */
  private handleRemove(file: any) {
    let targetIndex: any = "";
    this.c.value.forEach((f: any, index: any) => {
      if (f.url === file.url) {
        targetIndex = index;
      }
    });
    this.c.value.splice(targetIndex, 1);
    this.$emit("update", this.c);
  }
  /**
   * @description 上传
   */
  private upload(d: any) {
    const fileObj = d.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    UploadFile(this, form).then((data: any) => {
      this.c.value.forEach((file: any) => {
        if (file.uid === d.file.uid) {
          file.url = data.full_file_url;
        }
        delete file.size;
        delete file.status;
        delete file.percentage;
        delete file.raw;
      });
      this.$emit("update", this.c);
    });
    const uploadFilePath: any = "ds/file/" + d.file.name;
    // let fd: any = new FormData();
    // fd.append("type", "file");
    // const params: any = {
    //   file: "",
    //   fd,
    // };
    // const reader = new FileReader();
    // reader.readAsDataURL(d.file);
    // reader.onload = (e: any) => {
    //   fd.append("file", e.target["result"]);
    //   params["file"] = e.target["result"];
    //   UploadFile(this, fd).then((data: any) => {
    //     this.c.value.forEach((file: any) => {
    //       if (file.uid === d.file.uid) {
    //         file.url = data.image_url;
    //       }
    //       delete file.size;
    //       delete file.status;
    //       delete file.percentage;
    //       delete file.raw;
    //     });
    //     this.$emit("update", this.c);
    //   });
    // };
  }
  /**
   * @description 图片预览
   */
  private handlePictureCardPreview(file: any) {
    window.open(file.url);
  }
  /**
   * @description 文件变化时
   */
  private handleFileChange(file: any, fileList: any) {
    this.c.value = fileList;
  }
}
