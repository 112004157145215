






















































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Form as elForm } from "element-ui";
import MainHeader from "../../../main/components/main-header/main-header.vue";
import NavLeft from "../../../main/components/nav-left/nav-left.vue";
import Wenjian from "@/components/wenjian.vue";
import Agree from "../../../agree/agree.vue";
import SelectDuoxuan from "@/components/select/select-duoxuan.vue";
import { PostLaiyuans } from "@/request/yichang";
const cityData = require("@/assets/pca-code.json");
import {
  patientDetail,
  getDiseases,
  editPatient,
  addPatient,
  GetPatientSearch,
  getOptions,
} from "@/request/doctor";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Wenjian,
    Agree,
    SelectDuoxuan,
  },
})
export default class Name extends Vue {
  private disabledStartDate: any = this.endDate();
  private ifView: boolean = false;
  private showAgree: boolean = false;
  private ifQianyue: boolean = false;
  private ifShowSelect: boolean = false;
  private cityOption: any;
  private diseases: any = [];
  private identity: any = [];
  private zhengjians: any = [];
  private taocans: any = [
    "心脏康复运动",
    "营养减重",
    "功能医学抗衰老",
    "精准就医",
    "中医调理",
    "健康管理",
  ];
  private detail: any = {}; //用来记录初始值，处理未分配时第一次修改给创建医生字段
  private biaoqianData: any = {};
  private data: any = {
    姓名: "",
    客户身份: "",
    证件类型: "身份证",
    标签: [],
    身份证号: "",
    手机号: "",
    性别: "",
    出生日期: "",
    疾病: [],
    现居住在: "",
    现居住地区: "",
    地址: [],
    详细地址: "",
    紧急联系人: "",
    紧急联系人电话: "",
    agree: false,
    diseases: [],
    来源: [],
  };
  private rules: any = {
    姓名: [{ required: true, message: "请填写姓名", trigger: "blur" }],
    // 身份证号: [{ validator: this.checkIdcard, trigger: "blur" }],
    手机号: [{ validator: this.checkPhone, trigger: "blur" }],
    性别: [{ required: true, message: "请选择性别", trigger: "blur" }],
    出生日期: [{ required: true, message: "请选择出生日期", trigger: "blur" }],
    身高: [{ required: true, message: "请输入身高", trigger: "blur" }],
    客户身份: [{ required: false, message: "请选择客户身份", trigger: "blur" }],
    // 疾病: [{ required: true, message: "请选择疾病", trigger: "blur" }],
    // 地址: [{ required: false, message: "请选择地址", trigger: "blur" }],
    // 紧急联系人电话: [{ validator: this.checkPhone2, trigger: "blur" }],
    agree: [
      {
        required: true,
        message: "请同意《知情同意书》和《随访管理承诺书》",
        trigger: "blur",
      },
    ],
  };
  private nameOptions: any = [];
  private xinzangOptions: any = [
    "心脏骤停或心肌梗死或冠脉搭桥手术",
    "缺血性卒中或短暂性脑缺血发作",
    "急性冠脉综合征",
    "稳定性冠心病",
    "缺血性心肌病",
    "外周动脉疾病（除冠状动脉及主动脉以外的其他大动脉引起的动脉粥样硬化性病变，导致腿部、手臂或其他部位动脉血流减少）",
    "血管成形术或支架置入术",
    "冠状动脉造影或冠状动脉CT证实两支或以上主要冠状动脉狭窄>50%",
    "超声证实劲动脉狭窄>50%",
    "糖尿病≥10年或有靶器官损害（微量白蛋白尿、视网膜病变或肾病）",
    "I型糖尿病患者病程>20年",
    "中重度慢性肾病（eGFR<60 ml/（min*1.73m*m））",
    "家族性高胆固醇血症",
    "总胆固醇>8.0mmol/L和（或）低密度脂蛋白（LDL-C）>4.9mmol/L",
    "血压≥180/110 mmHg",
    "泛血管疾病的病史",
    "我没有上述疾病或不知道",
  ];
  private laiyuans: any = [];
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get user() {
    return this.$store.state.user || {};
  }
  private sourceChange() {
    if (this.detail["来源"] === "未分配") {
      if (this.data["来源"] === "未分配") {
        this.data.doctor_id = "";
      } else {
        this.data.doctor_id = this.user.doctor_id;
      }
    }
  }
  private jibingChange(e: any) {
    this.$forceUpdate();
  }
  private updateAgree(e: any) {
    this.showAgree = e;
  }
  private reset() {
    this.data = {
      姓名: "",
      证件类型: "",
      客户身份: "",
      标签: [],
      身份证号: "",
      手机号: "",
      性别: "",
      出生日期: "",
      疾病: [],
      现居住在: "",
      现居住地区: "",
      地址: [],
      详细地址: "",
      紧急联系人: "",
      紧急联系人电话: "",
      agree: false,
    };
  }
  private getIdentity() {
    const params: any = {
      data_type: "客户身份",
    };
    getOptions(this, params).then((res: any) => {
      this.identity = res["客户身份"];
    });
  }
  private getZhengjians() {
    const params: any = {
      data_type: "证件类型",
    };
    getOptions(this, params).then((res: any) => {
      this.zhengjians = res["证件类型"];
    });
  }
  private openBiaoqian() {
    const params: any = {
      data_type: "疾病类型",
    };
    getOptions(this, params).then((res: any) => {
      this.biaoqianData = res;
      this.ifShowSelect = true;
    });
  }
  private biaoqianSelectChange(e: any) {
    this.data["标签"] = e;
    this.ifShowSelect = false;
  }
  private nameChange(e: any) {
    this.data = e;
  }
  private getLaiyuan() {
    PostLaiyuans(this).then((res: any) => {
      this.laiyuans = res;
    });
  }
  private remoteMethod(val: any) {
    this.data["姓名"] = val;
    const params = {
      search: val,
    };
    GetPatientSearch(this, params).then((res: any) => {
      res.forEach((item: any) => {
        item.text = "";
        if (item["姓名"]) {
          item.text = item["姓名"] + "-";
        }
        if (item["身份证号"]) {
          item.text += item["身份证号"];
        }
      });
      this.nameOptions = res;
    });
  }
  private changeAgree(e: any) {
    this.data.agree = !this.data.agree;
    this.$forceUpdate();
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        return time.getTime() > Date.now();
      },
    };
  }
  private checkPhone(rule: any, value: any, callback: any) {
    if (!value) {
      return callback(new Error("请填写手机号"));
    } else {
      const reg = /^\d{11}$/;
      if (!reg.test(value)) {
        callback(new Error("手机号必须为11位数字"));
      }
    }
    callback();
  }
  private checkPhone2(rule: any, value: any, callback: any) {
    if (value) {
      const reg = /^\d{11}$/;
      if (!reg.test(value)) {
        callback(new Error("紧急联系人电话必须为11位数字"));
      }
    }
    callback();
  }
  private checkIdcard(rule: any, value: any, callback: any) {
    if (!value) {
      // return callback(new Error("请填写身份证号"));
    } else {
      const reg = /^[a-zA-Z0-9]{18}$/;
      if (!reg.test(value)) {
        callback(new Error("身份证号必须为18位数字或字母"));
      }
    }
    callback();
  }
  private checkManbing(rule: any, value: any, callback: any) {
    if (!value || value.length === 0) {
      return callback(new Error("请选择慢病"));
    }
    callback();
  }
  private isChecked(item: any) {
    if (!this.data["疾病"] || this.data["疾病"].length === 0) {
      return false;
    }
    let result: any = [];
    let rel = false;
    this.data["疾病"].forEach((ele: any) => {
      result.push(ele["名称"]);
    });
    if (result.indexOf(item) !== -1) {
      rel = true;
    } else {
      rel = false;
    }
    return rel;
  }
  private diseasesChange(item: any) {
    item.checked = !item.checked;
    this.data["疾病"] = [];
    this.diseases.forEach((ele: any) => {
      if (ele.checked) {
        this.data["疾病"].push(ele);
      }
    });
  }
  private onShenfenzhengBlur(e: any) {
    if (this.data["出生日期"]) {
      return;
    }
    const strId = this.data["身份证号"].toString();
    if (strId.length !== 18) {
      return;
    }
    const year: any = Number(strId.substr(6, 4));
    const month: any = Number(strId.substr(10, 2));
    const day: any = Number(strId.substr(12, 2));
    if (year < 1900 || year > 2200) {
      return;
    }
    if (month < 1 || month > 12) {
      return;
    }
    if (day < 1 || day > 31) {
      return;
    }
    if ([1, 3, 5, 7, 8, 10, 12].indexOf(month) == -1) {
      if (day > 30) {
        return;
      }
    }
    if (year % 4 !== 0 && month === 2 && day > 28) {
      return;
    }
    this.data["出生日期"] = "" + year + "-" + month + "-" + day;
  }
  private save() {
    (this.$refs["myForm"] as elForm).validate((isValid: boolean) => {
      if (!this.data.agree) {
        this.$message.warning("请同意《健康管理知情同意书》");
        return;
      }
      this.data.source = "pc医生";
      this.data["签约"] = this.ifQianyue;
      if (!this.ifView && !this.$route.query.patient_id) {
        this.data["签约"] = true;
      }
      if (isValid) {
        if (this.data.patient_id) {
          editPatient(this, this.data).then((res: any) => {
            this.$message.success("保存成功");
            const params = {
              patient_id: res.patient_id,
              source: "pc医生",
            };
            patientDetail(this, params).then((res: any) => {
              this.$store.commit("updatePatient", res);
              this.$router.push("/main/workstation/patient-file");
            });
          });
        } else {
          // 新增患者
          this.data.doctor_id = this.user.doctor_id;
          editPatient(this, this.data).then((d: any) => {
            const params = {
              patient_id: d.patient_id,
              source: "pc医生",
            };
            patientDetail(this, params).then((res: any) => {
              this.$message.success("新增成功");
              localStorage.setItem("patientFileFilter", "");
              this.$store.commit("updatePatient", res);
              this.$router.push("/main/workstation/patient-file");
            });
          });
        }
      }
    });
  }
  private getData() {
    const params = {
      patient_id: this.data.patient_id,
      source: "pc医生",
    };
    patientDetail(this, params).then((res: any) => {
      if (!res.diseases) {
        res.diseases = [];
      }
      // res["来源"] = [];
      // if (!res["来源"]) {
      //   res["来源"] = [];
      // }
      this.detail = JSON.parse(JSON.stringify(res));
      this.data = res;
    });
  }
  private getDiseases() {
    getDiseases(this).then((res: any) => {
      res.forEach((element: any) => {
        element.checked = false;
      });
      res.forEach((element: any) => {
        this.data["疾病"].forEach((ele: any) => {
          if (element.disease_id === ele.disease_id) {
            element.checked = true;
          }
        });
      });
      this.diseases = res;
    });
  }
  /**
   * @description 加载三级联动数据，加载缓存的验证码等待时间
   */
  private beforeMount() {
    this.cityOption = cityData;
  }
  private updateBingshi(d: any) {
    this.data["病史资料"] = d;
  }
  private goAgree() {
    this.showAgree = true;
  }
  mounted() {
    this.getLaiyuan();
    this.getIdentity();
    this.getZhengjians();
    if (this.$route.query.if_view === "true") {
      this.ifView = true;
    }
    if (this.$route.query["签约"] === "true") {
      this.ifQianyue = true;
    }
    if (this.$route.query.patient_id) {
      this.data.patient_id = this.$route.query.patient_id;
      this.getData();
    }
    this.getDiseases();
  }
}
