


























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private title: any; // 标题
  @Prop({ type: String, default: "name" })
  private nameKey: any;
  @Prop({ type: Number, default: -1 })
  private maxNum: any;
  @Prop({ type: Boolean, default: false })
  private ifBlock: any; // 导航选项是否单独一行
  @Prop({ type: Boolean, default: false })
  private hideInput: any; // 是否隐藏自定义输入框
  @Prop()
  private labelW: any; // 左边分类的宽度
  @Prop()
  private selectVal: any; // 选择的值
  @Watch("selectVal", { immediate: true })
  private selectValChange() {
    if (this.selectVal && this.selectVal.length > 0) {
      this.value = JSON.parse(JSON.stringify(this.selectVal));
    } else {
      this.value = [];
    }
  }
  @Prop()
  private ifShow: any; // 弹框是否显示
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    this.visible = this.ifShow;
  }
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.d = [];
    for (const key in this.data) {
      if (Object.prototype.hasOwnProperty.call(this.data, key)) {
        const ele = this.data[key];
        const obj = {
          name: key,
          children: ele,
        };
        this.d.push(obj);
      }
    }
    // 处理传入的数据，生成结构选项
    this.options = [];
    this.d.forEach((element: any) => {
      element.children.forEach((ele: any) => {
        ele.name = ele[this.nameKey];
        this.options.push(ele);
      });
    });
  }
  private options: any = []; // 根据传入的data处理的打平选项
  private visible: any = true;
  private d: any = [];
  private value: any = [];
  private value2: any = "";
  private delTag(i: any) {
    this.value.splice(i, 1);
  }
  private blur() {
    this.value2 = "";
  }
  private clickSelect() {
    (this.$refs.textInput as any).focus();
  }
  private enter() {
    let text: any = this.value2;
    if (this.value2) {
      text = this.value2.trim();
    }
    if (!text) {
      return;
    }
    let ifExit: any = false;
    this.value.forEach((ele: any) => {
      if (ele[this.nameKey] === this.value2) {
        ifExit = true;
      }
    });
    if (ifExit) {
      this.value2 = "";
    } else {
      if (this.maxNum != -1 && this.value.length > this.maxNum - 1) {
        this.$message.warning("最多只能添加" + this.maxNum + "个");
        return;
      }
      let obj: any = {};
      obj[this.nameKey] = this.value2;
      this.value.push(obj);
      this.value2 = "";
    }
  }
  private cancel() {
    this.$emit("cancel");
  }
  private makeSure() {
    let d: any = JSON.parse(JSON.stringify(this.value));
    if (this.nameKey != "name") {
      d.forEach((ele: any) => {
        delete ele.name;
      });
    }
    this.$emit("change", d);
  }
  // 点击下面的结构选择
  private select(item: any) {
    if (this.ifSelected(item)) {
      // 删除一个
      let index: any;
      this.value.forEach((ele: any, i: any) => {
        if (ele[this.nameKey] === item.name) {
          index = i;
        }
      });
      this.value.splice(index, 1);
    } else {
      //添加一个
      if (this.maxNum != -1 && this.value.length > this.maxNum - 1) {
        this.$message.warning("最多只能添加" + this.maxNum + "个");
        return;
      }
      let obj: any = JSON.parse(JSON.stringify(item));
      obj[this.nameKey] = item.name;
      this.value.push(obj);
    }
  }
  private ifSelected(val: any) {
    let result: any = false;
    this.value.forEach((ele: any) => {
      if (ele[this.nameKey] === val.name) {
        result = true;
      }
    });
    return result;
  }
}
